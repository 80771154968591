import React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import Cta from "../../components/Cta";
import { graphql, Link, StaticQuery } from "gatsby";

import "../../styles/gift_card.scss";

import { LOCATIONS } from "../../config/cities";
import Button from "../../components/Button";
import YoutubeGif from "../../components/YoutubeGif";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import AccordionTextBlock from "../../components/AccordionTextBlock";
import { GTM_GIFT_BUY_NOW_CLICK } from "../../config/gtm_classes";

const CityItem = ({ city = "", link = "", price = "380" }) => (
  <Link
    className={`giftcard_list_item ${GTM_GIFT_BUY_NOW_CLICK}`}
    to={link}
    title={city}
  >
    <div className="giftcard_list_item_text">
      <h4>{city}</h4>
      <h6>{price} zł</h6>
    </div>
    <Button type="primary" size="M">
      <span>Kup Teraz</span>
    </Button>
  </Link>
);

const GiftCardPage = ({ location, graph }) => {
  return (
    <Layout location={location}>
      <Helmet
        bodyAttributes={{
          type: "cennik",
        }}
      >
        <title>Bon Podarunkowy</title>
      </Helmet>
      <div className="gift_card">
        <div className="gift_card_instructions">
          <h1>Bon Podarunkowy</h1>
          <h3>Zadbaj o zdrowie swoich bliskich już dziś! </h3>
          <div className="gift_card_cities_instructions">
            <p>
              Kup bon podarunkowy na{" "}
              <strong>wideodermatoskopowe badanie wszystkich znamion</strong>.
            </p>
            <p>
              Ten niecodzienny prezent umożliwia skorzystanie z najlepszej
              metody badania i monitorowania znamion skórnych potocznie zwanych
              pieprzykami. Poniżej prezentujemy jak wyglądają nasze Bony
              Podarunkowe.
            </p>
          </div>
        </div>
        <div className="gift_card_images_list_wrapper">
          <YoutubeGif url="https://youtu.be/Ocgdv62-XMI" />

          <div className="img_wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: graph.GiftCardImage,
                alt: "Bon Podarunkowy - wideodermatoskopia",
                title: "Bon Podarunkowy - wideodermatoskopia",
              }}
            />
          </div>
        </div>
        <div className="gift_card_instructions">
          <div className="gift_card_cities_instructions">
            <p>
              Jeśli dokonujesz zakupu na ostatnią chwilę, Bon Podarukowy możesz
              nabyć również w wersji elektronicznej lub w niektórych placówkach.
            </p>
            <p>
              Wideodermatoskopia polega na oglądaniu wszystkich znamion w dużym
              powiększeniu za pomocą specjalnej kamery oraz analizie ich obrazu
              przez lekarza. W trakcie badania zapisane zostaną także zdjęcia
              znamion wyznaczonych do obserwacji celem oceny dynamiki zmian w
              nich zachodzących w trakcie wizyty kontrolnej.
            </p>
          </div>
        </div>
        <div className="gift_card_instructions">
          <strong>Wybierz miasto</strong>, w którym ma zostać zrealizowany bon:
        </div>
        <div className="gift_card_cities_list_wrapper">
          {LOCATIONS.filter((location) => location.giftCardLink).map(
            (location) => (
              <CityItem
                key={location.city}
                city={location.city}
                price={location.price}
                link={location.giftCardLink}
              />
            )
          )}
        </div>

        <div className="gift_rules_instructions">
          <p>
            Zakup Bonu Podarunkowego jest równoznaczny z zapoznaniem się z
            treścią poniższego regulaminu i jego akceptacją w całości.
          </p>
        </div>
        <a className="anchor" id="dziekujemy"></a>

        <div className="gift_rules_accordion">
          <AccordionTextBlock
            data={{
              isOpened: false,
              title: "Regulamin",
              body: () => (
                <div>
                  <div>
                    Twoje Znamiona.pl oferuje sprzedaż bonów podarunkowych,
                    uprawniających do skorzystania z usługi wideodermatoskopii
                    na warunkach określonych w niniejszym Regulaminie.
                    <p>
                      W nieniejszym regulaminie Bonu Podarunkowego, pod
                      stosowanymi pojęciami należy rozumieć:
                    </p>
                    <ul>
                      <li>
                        Bon Podarunkowy - imienny dokument, uprawniający do
                        realizacji usługi badania znamion wideodermatoskopem.
                      </li>
                      <li>Klient - osoba, która nabywa bon podarunkowy. </li>
                      <li>
                        Wystawca Bonu Podarunkowego - Medify.me spółka z
                        ograniczoną odpowiedzialnością, z siedzibą przy ul.
                        Jagiellońska 12, 84-200 Kąpino, NIP 7773357647,
                        właściciel marki TwojeZnamiona.pl, Ogólnopolskiego
                        Centrum Dermatoskopowego Badania Znamion, oferującego
                        usługi diagnostyki zmian skórnych.
                      </li>
                    </ul>
                  </div>
                  <div>
                    WARUNKI KORZYSTANIA
                    <ol>
                      <li>
                        Bon jest imienny tzn. w momencie zakupu na Bonie
                        Podarunkowym uzupełniane jest imię i nazwisko
                        obdarowanego i tylko ta osoba może go wykorzystać. Nie
                        ma możliwości przekazania bonu innej osobie.
                      </li>
                      <li>
                        Okres ważności Bonu Podarunkowego widnieje na bonie i
                        wynosi 12 miesięcy od daty jego zakupu. W przypadku
                        zakupu online za datę zakupu uważa się datę
                        zaksięgowania wpłaty.
                      </li>
                      <li>
                        Jeśli Bon Podarunkowy został zakupiony na kwotę, która
                        stanowi równowartość kosztu wizyty w dniu zakupu w
                        konkretnej placówce, to oznacza, że w przypadku zmiany
                        cennika usług w trakcie 12 miesięcy od daty zakupu Bonu
                        Podarunkowego, obdarowanego obowiązuje cena aktualna w
                        dniu zakupu i Bon Podarunkowy może zostać wykorzystany,
                        bez dodatkowej opłaty.
                      </li>
                      <li>
                        Nie wykorzystanie Bonu Podarunkowego w podanym na nim
                        terminie jest równoznaczne z utratą ważności bonu.
                      </li>
                      <li>
                        Bon Podarunkowy uprawnia do skorzystania z usług
                        wymienionych w pkt 2, w następujących miastach: Poznań,
                        Warszawa, Wrocław, Szczecin, Kraków.
                      </li>
                      <li>
                        W przypadku zagubienia bonu, klient ma możliwość
                        skorzystania z usługi przedkładając dowód zakupu, numer
                        bonu oraz udostępniając do wglądu dokument tożsamości w
                        postaci dowodu osobistego, paszportu lub prawa jazdy.
                      </li>
                      <li>
                        Bon Podarunkowy nie jest środkiem płatniczym. Nie
                        podlega wymianie na środki pieniężne w postaci gotówki
                        lub zwrotu na kartę płatniczą.
                      </li>
                      <li>
                        Zakupu Bonu Podarunkowego może dokonać tylko osoba
                        pełnoletnia. W przypadku zakupu Bonu Podarunkowego dla
                        dziecka konieczne będzie pojawienie się na wizycie
                        dziecka wraz opiekunem prawnym. W innym przypadku usługa
                        nie będzie mogła zostać zrealizowana.
                      </li>
                    </ol>
                  </div>

                  <div>
                    WARUNKI REALIZACJI
                    <ol>
                      <li>
                        Warunkiem rozpoczęcia realizacji Bonu Podarunkowego jest
                        jego okazanie w postaci oryginalnej, fizycznej - jeśli
                        został zakupiony w placówce lub w wersji elektronicznej
                        - jeśli został zakupiony poprzez stronę internetową
                        on-line. Celem weryfikacji zgodności należy także
                        udostępnić do wglądu dokument tożsamości.
                      </li>
                      <li>
                        W celu uzgodnienia terminu wizyty należy skontaktować
                        się telefonicznie z recepcją wybranej placówki, online
                        przez stronę www.twojeznamiona.pl lub za pośrednictwem
                        adresu e-mail: zbadamy@twojeznamiona.pl. Przy
                        dokonywaniu zapisu na badanie wideodermatoskopowe należy
                        poinformować o tym, że płatność za usługę będzie
                        dokonywana za pomocą Bonu Podarunkowego.
                      </li>
                      <li>
                        W sytuacji braku możliwości realizacji rezerwacji w
                        uzgodnionym terminie, należy skontaktować się
                        telefonicznie bądź drogą mailową, na co najmniej 24
                        godziny przed umówioną wizytą celem wyznaczenia nowego
                        terminu.
                      </li>
                      <li>
                        Wystawca Bonu Podarunkowego zastrzega sobie prawo do
                        odmowy świadczenia usługi na wideodermatoskopowe badanie
                        znamion na podstawie Bonów Podarunkowych, w przypadku, w
                        którym Bon Podarunkowy jest: zniszczony, nieczytelny lub
                        występują uzasadnione wątpliwości dotyczące
                        autentyczności okazanego Bonu Podarunkowego.
                      </li>
                    </ol>
                  </div>
                  <div>
                    ZGŁASZANIE UWAG
                    <p>
                      Wszelkie uwagi dotyczące zakupu Bonów Podarunkowych należy
                      kierować i zgłaszać poprzez kontakt e-mail:
                      zbadamy@twojeznamiona.pl.
                    </p>
                  </div>
                  <div>
                    POSTANOWIENIA KOŃCOWE
                    <ol>
                      <li>
                        Jeżeli realizacja bonu podarunkowego będzie niemożliwa w
                        wybranej lokalizacji z winy Wystawcy Bonu Podarunkowego
                        (np. w danej miejscowości przestanie funkcjonować
                        ośrodek TwojeZnamion.pl), Wystawca Bonu Podarunkowego
                        wydłuży czas ważności bonu podarunkowego na okres
                        dodatkowych 6 miesięcy oraz zapewni możliwość realizacji
                        bonu w innej miejscowości, w której TwojeZnamiona.pl
                        posiada ośrodek honorujący bony podarunkowe.
                      </li>
                      <li>
                        Zakup Bonu Podarunkowego jest równoznaczny z tym, że
                        Kupujący zapoznał się z treścią regulaminu i akceptuje
                        go w całości.
                      </li>
                      <li>
                        Wystawca Bonu Podarunkowego zastrzega sobie prawo do
                        zmiany niniejszego regulaminu dotyczącego Bonów
                        Podarunkowych bez uprzedzenia. O wprowadzonych zmianach
                        poinformuje Klienta poprzez udostępnienie uaktualnionego
                        Regulaminu na stronie internetowej twojeznamiona.pl
                      </li>
                      <li>
                        Regulamin wchodzi w życie z dniem 01.01.2023r. i
                        obowiązuje przez cały okres sprzedaży Bonów
                        Podarunkowych.
                      </li>
                      <li>
                        Administratorem Twoich danych osobowych w związku z
                        udzielanymi świadczeniami medycznymi jest Medify.me
                        spółka z ograniczoną odpowiedzialnością, z siedzibą przy
                        ul. Jagiellońska 12 84-200 Kąpino, NIP 7773357647.
                      </li>
                    </ol>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="gift_card_instructions">
          <h3>
            Po zakupie Bonu Podarunkowego, pozostał Tobie jeszcze tylko jeden
            krok!
          </h3>
          <div className="gift_card_cities_instructions">
            <p>
              Napisz do nas wiadomość na maila{" "}
              <a href="mailto:zbadamy@twojeznamiona.pl">
                zbadamy@twojeznamiona.pl
              </a>
              , w którym podasz:
            </p>
            <ul>
              <li>
                <strong>swoje imię i nazwisko</strong> podane w procesie
                płatności,
              </li>
              <li>
                <strong>imię i nazwisko</strong> osoby obdarowanej,
              </li>
              <li>
                <strong>adres paczkomatu</strong> lub <strong>adres</strong> do
                wysyłki bonu.
              </li>
            </ul>
            <p>
              Jeżeli nie możesz czekać na wysyłkę i potrzebujesz Bonu w{" "}
              <strong>wersji elektronicznej</strong> - żaden problem, po prostu
              poinformuj nas o tym w swojej wiadomości.
            </p>
            <p>
              Bony Podarunkowe wysyłamy na bieżąco, zazwyczaj w przeciagu 48
              godzin od daty otrzymania Twojego maila.
            </p>
          </div>
        </div>
      </div>

      <Cta className="home_cta" />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        GiftCardImage: allFile(
          filter: { relativePath: { regex: "/bon/bon.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 750, maxHeight: 561, quality: 95) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <GiftCardPage
        graph={{
          GiftCardImage: data.GiftCardImage.nodes[0],
        }}
        {...props}
      />
    )}
  />
);
