import React, { useState } from "react";
import "../styles/accordion_text_block.scss";

import ChevronIcon from "../img/chevron-up.svg";

const AccordionTextBlock = ({ data = {} }: any) => {
  const [isOpened, setIsOpened] = useState(data?.isOpened);
  const Body = data.body || null;

  return (
    <div className="accordion_text_block">
      <div className="accordion_text_block_category_wrapper">
        <div
          className={
            isOpened
              ? "accordion_text_block_category  accordion_text_block_category--opened"
              : "accordion_text_block_category"
          }
          onClick={() => setIsOpened(!isOpened)}
        >
          <h3 className="accordion_text_block_category_label">{data.title}</h3>

          <div className="accordion_text_block_category_button_wrapper">
            <div className="accordion_text_block_category_expand">
              <span className="accordion_label">
                {isOpened ? "Zwiń" : "Zobacz"}
              </span>
              <img
                className={isOpened ? "" : "flip"}
                src={ChevronIcon}
                alt={isOpened ? "Zwiń" : "Zobacz"}
                title={isOpened ? "Zwiń" : "Zobacz"}
              />
            </div>
          </div>
        </div>
        <div
          className={
            isOpened
              ? "accordion_text_block_category_items accordion_text_block_category_items--opened"
              : "accordion_text_block_category_items"
          }
        >
          <div className="accordion_text_block_item">
            <div className="accordion_text_block_name">
              <div className="accordion_text_block_item_label">
                <Body />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionTextBlock;
